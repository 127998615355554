<!--
 * @Author: madsion
 * @Date: 2021-10-20 10:17:02
 * @LastEditors: madsion
 * @LastEditTime: 2021-10-20 16:04:34
 * @Description: 
-->
<template>
  <div class="chidl_layout">
    <TextLayout v-if="this.childLevel === '2'"></TextLayout>
    <ThumbnailLayout v-else-if="this.childLevel === '3'"></ThumbnailLayout>
  </div>
</template>

<script>
import TextLayout from "@/components/commonLayout/textLayout";
import ThumbnailLayout from "@/components/commonLayout/thumbnailLayout";
export default {
  name: "childCommonLayout",
  components: {
    TextLayout,
    ThumbnailLayout
  },
  data() {
    return {
      childLevel: ""
    };
  },
  mounted() {
    this.childLevel = this.$route.query.level;
  }
};
</script>
